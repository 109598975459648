<template>
    <svg
        viewBox="0 0 30 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M23.3592 0.0293799C16.9891 0.540248 11.5437 3.72798 7.86277 9.10098C7.2129 10.0496 5.67995 13.0107 5.67995 13.3173C5.67995 13.3905 4.94934 13.4645 3.89323 13.4982C2.64001 13.5382 1.97397 13.6143 1.66276 13.7531C0.145234 14.4297 -0.450216 16.356 0.368293 17.9408C0.656301 18.4985 0.859549 18.7102 1.39607 19.0115C1.97033 19.3341 2.23138 19.3958 3.24549 19.4488L4.42611 19.5105V20.9781V22.4458L3.2663 22.5121C2.62835 22.5485 1.90689 22.6646 1.66276 22.77C0.151002 23.4233 -0.450467 25.3664 0.368293 26.9517C0.65906 27.5148 0.858296 27.7205 1.41688 28.0345C2.08079 28.4078 2.17182 28.424 3.86188 28.4688L5.61726 28.5153L6.17447 29.7731C7.53452 32.8436 9.42118 35.2857 12.1373 37.4921C14.7631 39.625 17.8214 41.032 21.1277 41.628C22.691 41.9097 26.2593 42.0932 27.4021 41.9505C29.7638 41.6556 30.7961 38.9871 29.3038 37.0344C28.6815 36.2199 28.2137 36.0894 25.5534 35.9874C22.4291 35.8677 21.1694 35.6466 19.2215 34.8759C16.8852 33.9516 14.2973 31.777 12.8098 29.4884L12.1998 28.5498L18.5631 28.4872C23.6364 28.4373 24.99 28.3897 25.2399 28.2525C25.9727 27.85 26.4138 27.4133 26.6941 26.8123C27.3804 25.3413 26.8242 23.555 25.4521 22.823C24.9275 22.543 24.9124 22.5424 17.6855 22.5065L10.4446 22.4703V20.9733V19.4763L17.3721 19.4733C21.1821 19.4717 24.5211 19.4191 24.7918 19.3564C27.3402 18.7664 27.7822 15.0552 25.4521 13.8121C24.9324 13.5347 24.8552 13.5308 18.5665 13.469L12.2065 13.4064L12.7361 12.5689C13.7815 10.9159 16.0029 8.78973 17.7205 7.79816C19.8344 6.57793 22.5276 5.96456 25.8042 5.9573C27.5782 5.95342 28.1161 5.84855 28.8041 5.37285C30.5275 4.1814 30.3315 1.28202 28.4613 0.300208C27.9889 0.0521576 27.7327 0.0221211 25.9295 0.00422441C24.8262 -0.00678892 23.6695 0.00447474 23.3592 0.0293799Z"
            fill="currentColor"
        />
    </svg>
</template>
