<template>
    <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M23.707 11.293L12.707 0.293031C12.5195 0.10556 12.2652 0.000244141 12 0.000244141C11.7349 0.000244141 11.4806 0.10556 11.293 0.293031L0.293031 11.293C0.153222 11.4329 0.0580152 11.611 0.0194476 11.805C-0.0191199 11.999 0.000683047 12.2 0.0763531 12.3827C0.152023 12.5654 0.280163 12.7215 0.444573 12.8314C0.608982 12.9413 0.802281 13 1.00003 13H2.25003C2.31634 13 2.37992 13.0264 2.42681 13.0733C2.47369 13.1201 2.50003 13.1837 2.50003 13.25V23C2.50003 23.2652 2.60539 23.5196 2.79292 23.7071C2.98046 23.8947 3.23481 24 3.50003 24H9.25003C9.31634 24 9.37992 23.9737 9.42681 23.9268C9.47369 23.8799 9.50003 23.8163 9.50003 23.75V19C9.50003 18.337 9.76342 17.7011 10.2323 17.2323C10.7011 16.7634 11.337 16.5 12 16.5C12.6631 16.5 13.299 16.7634 13.7678 17.2323C14.2366 17.7011 14.5 18.337 14.5 19V23.75C14.5 23.8163 14.5264 23.8799 14.5733 23.9268C14.6201 23.9737 14.6837 24 14.75 24H20.5C20.7652 24 21.0196 23.8947 21.2071 23.7071C21.3947 23.5196 21.5 23.2652 21.5 23V13.25C21.5 13.1837 21.5264 13.1201 21.5733 13.0733C21.6201 13.0264 21.6837 13 21.75 13H23C23.1978 13 23.3911 12.9413 23.5555 12.8314C23.7199 12.7215 23.848 12.5654 23.9237 12.3827C23.9994 12.2 24.0192 11.999 23.9806 11.805C23.942 11.611 23.8468 11.4329 23.707 11.293Z"
            fill="currentColor"
        />
    </svg>
</template>
