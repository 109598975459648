<template>
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M16.2319 2.54637C16.4586 1.39377 15.7217 0.272192 14.5881 0.041671C13.4544 -0.18885 12.3513 0.560344 12.1245 1.71295L12.0242 2.21832C11.7543 3.59893 11.0431 4.8469 10.0023 5.77198C9.99792 5.8002 9.99294 5.82845 9.98738 5.85672L9.88709 6.36209C9.656 7.54573 9.2287 8.67174 8.63135 9.69578H14.9101C16.0655 9.69578 17.003 10.6489 17.003 11.8237C17.003 12.6438 16.5451 13.3575 15.8737 13.7122C16.3489 14.1023 16.6541 14.7008 16.6541 15.3702C16.6541 16.4075 15.9216 17.272 14.958 17.4581C15.1499 17.7818 15.2589 18.1586 15.2589 18.562C15.2589 19.0513 15.0961 19.5026 14.8227 19.8621H18.0152C19.1706 19.8621 20.1081 18.909 20.1081 17.7342C20.1081 17.5746 20.0906 17.4194 20.0601 17.2731C20.8973 16.9983 21.5034 16.1959 21.5034 15.2516C21.5034 14.8482 21.3943 14.4714 21.2025 14.1478C22.1661 13.9616 22.8986 13.0972 22.8986 12.0598C22.8986 11.3904 22.5934 10.7919 22.1181 10.4018C22.7896 10.0472 23.2474 9.33345 23.2474 8.51333C23.2474 7.33856 22.31 6.38544 21.1545 6.38544H14.8758C15.4732 5.36139 15.9005 4.23538 16.1316 3.05175L16.2319 2.54637Z"
            fill="currentColor"
        />
        <path
            d="M6.68857 4.17937C7.82223 4.40989 8.55911 5.53146 8.33237 6.68407L8.23209 7.18944C8.001 8.37308 7.5737 9.49909 6.97635 10.5231H13.2551C14.4105 10.5231 15.348 11.4763 15.348 12.651C15.348 13.4711 14.8901 14.1849 14.2187 14.5395C14.6939 14.9296 14.9991 15.5281 14.9991 16.1975C14.9991 17.2349 14.2666 18.0993 13.303 18.2855C13.4949 18.6091 13.6039 18.9859 13.6039 19.3893C13.6039 20.3336 12.9978 21.136 12.1606 21.4108C12.1912 21.5571 12.2086 21.7123 12.2086 21.8719C12.2086 23.0466 11.2712 23.9998 10.1157 23.9998H5.86449C5.03605 23.9998 4.22941 23.7515 3.5405 23.286L1.86181 22.1467C0.697634 21.3576 0 20.0277 0 18.6047V16.9068V14.7789V13.6751C0 12.3806 0.579909 11.1615 1.56968 10.3502L1.89233 10.0887C3.04779 9.14887 3.83699 7.82781 4.12477 6.35602L4.22505 5.85065C4.45178 4.69804 5.55492 3.94885 6.68857 4.17937Z"
            fill="currentColor"
        />
    </svg>
</template>
