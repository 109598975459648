<template>
    <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M11.5366 0.0503525C12.9064 0.328899 13.7968 1.68414 13.5229 3.07687L13.4017 3.68753C13.1225 5.11776 12.6061 6.47835 11.8843 7.71574H19.4711C20.8673 7.71574 22 8.86742 22 10.2869C22 11.2779 21.4468 12.1403 20.6355 12.5689C21.2098 13.0403 21.5786 13.7634 21.5786 14.5723C21.5786 15.8257 20.6934 16.8703 19.5291 17.0953C19.7609 17.4863 19.8926 17.9416 19.8926 18.4291C19.8926 19.57 19.1603 20.5396 18.1487 20.8717C18.1856 21.0485 18.2067 21.236 18.2067 21.4288C18.2067 22.8483 17.0739 24 15.6777 24H10.5409C9.53982 24 8.56513 23.7 7.73269 23.1376L5.70428 21.7609C4.29756 20.8074 3.45459 19.2004 3.45459 17.4809V15.4293V12.8581V11.5243C3.45459 9.96018 4.15531 8.4871 5.35128 7.50683L5.74116 7.19079C7.13734 6.05517 8.09095 4.45889 8.43868 2.68047L8.55986 2.06981C8.83383 0.677082 10.1668 -0.228194 11.5366 0.0503525Z"
            fill="currentColor"
        />
    </svg>
</template>
