<script setup lang="ts">
import {
    AntlerClasses,
    installAntlerComponent,
    responsive,
} from "@/Utils/component";
import IconArrowDown from "@/Components/Icons/IconArrowDown.vue";
import IconAlert from "@/Components/Icons/IconAlert.vue";
import IconArrowBack from "@/Components/Icons/IconArrowBack.vue";
import IconArrowUp from "@/Components/Icons/IconArrowUp.vue";
import IconCalendar from "@/Components/Icons/IconCalendar.vue";
import IconCheck from "@/Components/Icons/IconCheck.vue";
import IconCollection from "@/Components/Icons/IconCollection.vue";
import IconCollectionEmpty from "@/Components/Icons/IconCollectionEmpty.vue";
import IconCross from "@/Components/Icons/IconCross.vue";
import IconDots from "@/Components/Icons/IconDots.vue";
import IconEdit from "@/Components/Icons/IconEdit.vue";
import IconEuro from "@/Components/Icons/IconEuro.vue";
import IconFlag from "@/Components/Icons/IconFlag.vue";
import IconHeartEmpty from "@/Components/Icons/IconHeartEmpty.vue";
import IconHeartFull from "@/Components/Icons/IconHeartFull.vue";
import IconInfo from "@/Components/Icons/IconInfo.vue";
import IconInventory from "@/Components/Icons/IconInventory.vue";
import IconLink from "@/Components/Icons/IconLink.vue";
import IconMenu from "@/Components/Icons/IconMenu.vue";
import IconPin from "@/Components/Icons/IconPin.vue";
import IconPlus from "@/Components/Icons/IconPlus.vue";
import IconPoints from "@/Components/Icons/IconPoints.vue";
import IconQuote from "@/Components/Icons/IconQuote.vue";
import IconReply from "@/Components/Icons/IconReply.vue";
import IconSearch from "@/Components/Icons/IconSearch.vue";
import IconShare from "@/Components/Icons/IconShare.vue";
import IconShop from "@/Components/Icons/IconShop.vue";
import IconSpark from "@/Components/Icons/IconSpark.vue";
import IconStar from "@/Components/Icons/IconStar.vue";
import IconThumb from "@/Components/Icons/IconThumb.vue";
import IconTwitter from "@/Components/Icons/IconTwitter.vue";
import IconUser from "@/Components/Icons/IconUser.vue";
import IconWebsite from "@/Components/Icons/IconWebsite.vue";
import IconWinner from "@/Components/Icons/IconWinner.vue";
import IconList from "@/Components/Icons/IconList.vue";
import IconFacebook from "@/Components/Icons/IconFacebook.vue";
import IconHome from "@/Components/Icons/IconHome.vue";
import IconThumbDown from "@/Components/Icons/IconThumbDown.vue";
import IconThumbs from "@/Components/Icons/IconThumbs.vue";
import IconNotification from "@/Components/Icons/IconNotification.vue";
import IconTrash from "@/Components/Icons/IconTrash.vue";
import IconComments from "@/Components/Icons/IconComments.vue";
import IconLock from "@/Components/Icons/IconLock.vue";
import IconEye from "@/Components/Icons/IconEye.vue";
import IconEyeClosed from "@/Components/Icons/IconEyeClosed.vue";
import IconCaretUp from "@/Components/Icons/IconCaretUp.vue";
import IconCaretDown from "@/Components/Icons/IconCaretDown.vue";
import IconWishlist from "@/Components/Icons/IconWishlist.vue";
import IconMinus from "@/Components/Icons/IconMinus.vue";
import IconDrag from "@/Components/Icons/IconDrag.vue";
import IconBook from "@/Components/Icons/IconBook.vue";

export type IconName =
    | "alert"
    | "arrow-back"
    | "arrow-down"
    | "arrow-up"
    | "book"
    | "caret-up"
    | "caret-down"
    | "calendar"
    | "check"
    | "collection"
    | "collection-empty"
    | "cross"
    | "dots"
    | "drag"
    | "edit"
    | "euro"
    | "flag"
    | "trash"
    | "heart-empty"
    | "heart-full"
    | "info"
    | "inventory"
    | "notification"
    | "link"
    | "list"
    | "menu"
    | "minus"
    | "pin"
    | "plus"
    | "points"
    | "quote"
    | "reply"
    | "search"
    | "share"
    | "shop"
    | "spark"
    | "lock"
    | "star"
    | "thumb"
    | "comment"
    | "thumbs"
    | "thumb-down"
    | "twitter"
    | "facebook"
    | "user"
    | "home"
    | "website"
    | "eye"
    | "eye-closed"
    | "wishlist"
    | "winner";

export type IconSize =
    | "xxsmall"
    | "xsmall"
    | "small"
    | "regular"
    | "medium"
    | "large"
    | "xlarge";
export type IconColor = "current" | "primary" | "none" | "gray" | "grayLight";

type Props = {
    name: IconName;
    size?: IconSize;
    color?: IconColor;
};

const { size = "regular", color = "current" } = defineProps<Props>();

const classes: AntlerClasses<Props> = {
    base: "inline-flex items-center justify-center shrink-0 w-4 h-4 basis-4",
    variants: {
        size: {
            xxsmall: "h-2 w-2 basis-2",
            xsmall: "h-2.5 w-2.5 basis-2.5",
            small: "h-3 w-3 basis-3",
            regular: "h-4 w-4 basis-4",
            medium: responsive("h-5 w-5 basis-5"),
            large: responsive("h-6 w-6 basis-6"),
            xlarge: responsive("h-8 w-8 basis-8"),
        },
        color: {
            current: "text-current fill-current",
            primary: "text-primary fill-primary",
            gray: "text-slate-400 fill-slate-400",
            grayLight: "text-slate-300",
            none: "fill-none",
        },
    },
};

const { aClass } = installAntlerComponent("icon", { size, color }, classes);
</script>

<template>
    <IconAlert
        v-if="name === 'alert'"
        :class="aClass()"
    />
    <IconArrowBack
        v-else-if="name === 'arrow-back'"
        :class="aClass()"
    />
    <IconArrowDown
        v-else-if="name === 'arrow-down'"
        :class="aClass()"
    />
    <IconArrowUp
        v-else-if="name === 'arrow-up'"
        :class="aClass()"
    />
    <IconCalendar
        v-else-if="name === 'calendar'"
        :class="aClass()"
    />
    <IconCheck
        v-else-if="name === 'check'"
        :class="aClass()"
    />
    <IconCollection
        v-else-if="name === 'collection'"
        :class="aClass()"
    />
    <IconCollectionEmpty
        v-else-if="name === 'collection-empty'"
        :class="aClass()"
    />
    <IconCross
        v-else-if="name === 'cross'"
        :class="aClass()"
    />
    <IconDots
        v-else-if="name === 'dots'"
        :class="aClass()"
    />
    <IconEdit
        v-else-if="name === 'edit'"
        :class="aClass()"
    />
    <IconEuro
        v-else-if="name === 'euro'"
        :class="aClass()"
    />
    <IconFlag
        v-else-if="name === 'flag'"
        :class="aClass()"
    />
    <IconHeartEmpty
        v-else-if="name === 'heart-empty'"
        :class="aClass()"
    />
    <IconHeartFull
        v-else-if="name === 'heart-full'"
        :class="aClass()"
    />
    <IconInfo
        v-else-if="name === 'info'"
        :class="aClass()"
    />
    <IconInventory
        v-else-if="name === 'inventory'"
        :class="aClass()"
    />
    <IconLink
        v-else-if="name === 'link'"
        :class="aClass()"
    />
    <IconMenu
        v-else-if="name === 'menu'"
        :class="aClass()"
    />
    <IconPin
        v-else-if="name === 'pin'"
        :class="aClass()"
    />
    <IconPlus
        v-else-if="name === 'plus'"
        :class="aClass()"
    />
    <IconPoints
        v-else-if="name === 'points'"
        :class="aClass()"
    />
    <IconQuote
        v-else-if="name === 'quote'"
        :class="aClass()"
    />
    <IconReply
        v-else-if="name === 'reply'"
        :class="aClass()"
    />
    <IconSearch
        v-else-if="name === 'search'"
        :class="aClass()"
    />
    <IconShare
        v-else-if="name === 'share'"
        :class="aClass()"
    />
    <IconShop
        v-else-if="name === 'shop'"
        :class="aClass()"
    />
    <IconSpark
        v-else-if="name === 'spark'"
        :class="aClass()"
    />
    <IconStar
        v-else-if="name === 'star'"
        :class="aClass()"
    />
    <IconThumb
        v-else-if="name === 'thumb'"
        :class="aClass()"
    />
    <IconThumbDown
        v-else-if="name === 'thumb-down'"
        :class="aClass()"
    />
    <IconThumbs
        v-else-if="name === 'thumbs'"
        :class="aClass()"
    />
    <IconTwitter
        v-else-if="name === 'twitter'"
        :class="aClass()"
    />
    <IconUser
        v-else-if="name === 'user'"
        :class="aClass()"
    />
    <IconWebsite
        v-else-if="name === 'website'"
        :class="aClass()"
    />
    <IconWinner
        v-else-if="name === 'winner'"
        :class="aClass()"
    />
    <IconList
        v-else-if="name === 'list'"
        :class="aClass()"
    />
    <IconTrash
        v-else-if="name === 'trash'"
        :class="aClass()"
    />
    <IconMinus
        v-else-if="name === 'minus'"
        :class="aClass()"
    />
    <IconFacebook
        v-else-if="name === 'facebook'"
        :class="aClass()"
    />
    <IconHome
        v-else-if="name === 'home'"
        :class="aClass()"
    />
    <IconNotification
        v-else-if="name === 'notification'"
        :class="aClass()"
    />
    <IconComments
        v-else-if="name === 'comment'"
        :class="aClass()"
    />
    <IconLock
        v-else-if="name === 'lock'"
        :class="aClass()"
    />
    <IconEye
        v-else-if="name === 'eye'"
        :class="aClass()"
    />
    <IconEyeClosed
        v-else-if="name === 'eye-closed'"
        :class="aClass()"
    />
    <IconCaretUp
        v-else-if="name === 'caret-up'"
        :class="aClass()"
    />
    <IconCaretDown
        v-else-if="name === 'caret-down'"
        :class="aClass()"
    />
    <IconWishlist
        v-else-if="name === 'wishlist'"
        :class="aClass()"
    />
    <IconDrag
        v-else-if="name === 'drag'"
        :class="aClass()"
    />
    <IconBook
        v-else-if="name === 'book'"
        :class="aClass()"
    />
</template>
