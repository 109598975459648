<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
    >
        <path
            d="M0,19.59a4.039,4.039,0,0,0,4.035,4.035h15.93A4.039,4.039,0,0,0,24,19.59V17.716a1.225,1.225,0,1,0-2.449,0V19.59a1.588,1.588,0,0,1-1.586,1.586H4.035A1.588,1.588,0,0,1,2.449,19.59V17.716a1.225,1.225,0,1,0-2.449,0Z"
        />
        <path
            d="M12,19.322a1.959,1.959,0,0,0,1.959-1.959V8.2a.25.25,0,0,1,.25-.25H16.9a.979.979,0,0,0,.739-1.622L12.739.7a1.011,1.011,0,0,0-1.478,0l-4.9,5.633A.979.979,0,0,0,7.1,7.951H9.791a.25.25,0,0,1,.25.25v9.162A1.959,1.959,0,0,0,12,19.322Z"
        />
    </svg>
</template>
