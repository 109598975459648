<template>
    <svg
        viewBox="0 0 41 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M19.5702 0.170976C18.4031 0.59847 17.7639 1.23931 17.4876 2.25899C17.4063 2.55872 17.3552 5.64255 17.3548 10.2601L17.354 17.7736H10.0151C1.95942 17.7736 1.98756 17.771 1.01994 18.5887C0.48131 19.0438 0 20.1725 0 20.9803C0 22.1435 0.601868 23.2306 1.56843 23.8131L2.16925 24.1752L9.72875 24.2426L17.2882 24.31L17.354 31.9957L17.4197 39.6816L17.7539 40.3515C18.8501 42.5495 22.1684 42.5495 23.2646 40.3515L23.5988 39.6816L23.6645 31.9957L23.7303 24.31L31.2898 24.2426L38.8493 24.1752L39.4409 23.8178C41.7453 22.4255 41.4291 18.8335 38.9294 18.0059C38.2994 17.7974 37.4808 17.7736 30.952 17.7736H23.6765L23.6376 9.99054L23.5988 2.2075L23.2501 1.60103C22.8332 0.876098 22.3757 0.511138 21.5047 0.208711C20.7373 -0.0577313 20.222 -0.0677042 19.5702 0.170976Z"
            fill="currentColor"
        />
    </svg>
</template>
