<template>
    <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M11.6954 0.0326613C11.4118 0.104854 11.1423 0.268399 10.9225 0.501508C10.7852 0.647208 10.2494 1.71758 9.2353 3.87172L7.7493 7.02842L4.8304 7.48405C1.16313 8.0564 0.936518 8.09654 0.722729 8.21037C0.140737 8.52049 -0.165785 9.43652 0.092243 10.0948C0.161034 10.2704 0.872588 11.0365 2.67295 12.8731L5.15862 15.4089L4.58033 18.8526C4.16832 21.3059 4.01013 22.3886 4.03019 22.6171C4.10056 23.4172 4.68316 23.979 5.44813 23.9843C5.88026 23.9873 5.65456 24.0988 9.65145 21.908L11.9829 20.63L14.8995 22.2146C16.5037 23.086 17.9626 23.8517 18.1416 23.9161C18.5618 24.0672 18.9174 24.0159 19.3035 23.7485C19.6303 23.5223 19.8402 23.2334 19.9313 22.8848C20.0046 22.6044 19.9956 22.5364 19.3298 18.3452L18.858 15.3755L21.3007 12.9049C23.9628 10.2124 24.0024 10.1626 23.9999 9.50833C23.9973 8.77933 23.5741 8.25595 22.8547 8.0919C22.633 8.04137 21.057 7.78297 19.3524 7.5178C17.6478 7.25257 16.237 7.01771 16.2173 6.99586C16.1976 6.97401 15.5293 5.56734 14.7322 3.8699C13.8622 2.01743 13.1998 0.683712 13.0751 0.533628C12.7347 0.123888 12.1587 -0.0852391 11.6954 0.0326613Z"
            fill="currentColor"
        />
    </svg>
</template>
