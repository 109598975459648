<script setup lang="ts">
import { AntlerClasses, installAntlerComponent } from "@/Utils/component";
import { useSlots } from "vue";

export type LoaderSize = "xsmall" | "small" | "regular";
export type LoaderColor = "current";
export type LoaderVariant = "centered";

type Props = {
    size?: LoaderSize;
    color?: LoaderColor;
    variant?: LoaderVariant;
};
const {
    size = "regular",
    color = "current",
    variant = undefined,
} = defineProps<Props>();

const slots = useSlots();

// classes
const classes: AntlerClasses<Props> = {
    base: "inline-flex justify-center text-center items-center gap-3 text-slate-400",
    variants: {
        size: {
            xsmall: "h-3 w-3",
            small: "h-4 w-4",
            regular: "h-5 w-5",
        },
        color: {
            current: "text-currentColor",
        },
        variant: {
            centered: "flex",
        },
    },
};
const { aClass } = installAntlerComponent(
    "loader",
    { size, variant, color },
    classes,
);
</script>

<template>
    <div :class="aClass()">
        <svg
            class="animate-spin -ml-1 h-5 w-5 text-currentColor"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
        >
            <path
                class="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
        </svg>

        <div
            v-if="slots.default"
            class="ml-1"
        >
            <slot />
        </div>
    </div>
</template>
