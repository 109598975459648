import { Responsive } from "@/types/responsive";
import { AntlerClasses } from "@/Utils/component";

export type ButtonVariant = Responsive<
    "ghost" | "round" | "clean" | "outlined" | "outlinedPrimary"
>;
export type ButtonColor =
    | "primary"
    | "default"
    | "grayLight"
    | "gray"
    | "warning"
    | "dark"
    | "secondary";
export type ButtonAlign = "left" | "center";
export type ButtonSize = Responsive<
    "xxsmall" | "xsmall" | "small" | "regular" | "large" | "full" | "auto"
>;

type ButtonDefaults = {
    size: ButtonSize;
    variant: ButtonVariant;
    color: ButtonColor;
    align: ButtonAlign;
};

export const buttonClasses: AntlerClasses<ButtonDefaults> = {
    base: "inline-flex rounded touch-manipulation justify-center items-center gap-2 border font-semibold leading-6 no-underline hover:no-underline focus:outline-2 focus:outline-brand-red",
    variants: {
        size: {
            xxsmall: "py-1 px-2 text-xs leading-none gap-1",
            xsmall: "py-1 px-2.5 text-xs",
            small: "py-1.5 px-2.5 text-sm",
            regular: "py-1.5 px-4 text-base",
            large: "py-2 px-4 text-base",
            full: "grow w-full whitespace-nowrap",
            auto: "min-h-fit min-w-fit",
        },
        align: {
            left: "justify-start",
            center: "justify-center",
        },
        variant: {
            outlined:
                "bg-transparent border disabled:bg-transparent disabled:border-gray-200 disabled:text-gray-400 hover:bg-gray-50",
            ghost: "bg-transparent border-transparent text-currentcolor hover:bg-gray-100 disabled:bg-gray-100 hover:border-transparent",
            round: "rounded-full h-8 w-8 p-0 shrink-0",
            clean: "border-0 p-0 bg-transparent text-current-color leading-none hover:bg-transparent disabled:bg-transparent disabled:text-current-color",
            outlinedPrimary: "border-primary",
        },
        color: {
            default:
                "bg-white text-gray-800 hover:bg-gray-50 disabled:bg-gray-100 disabled:text-gray-400",
            primary:
                "bg-primary border-primary text-white disabled:bg-primary/60 active:bg-primaryDarkest disabled:text-white hover:bg-primaryDark",
            grayLight:
                "bg-slate-100 border-slate-100 text-slate-800 hover:bg-gray-200 disabled:bg-gray-200 disabled:text-gray-400",
            gray:
                "bg-slate-200 border-slate-300 text-slate-500 hover:bg-slate-300 disabled:bg-gray-200 disabled:text-gray-400",
            warning:
                "border border-orange-500 text-orange-500 hover:bg-orange-50 disabled:bg-orange-50 disabled:text-orange-400",
            dark: "bg-gray-500 text-white hover:bg-gray-700 disabled:bg-gray-900 disabled:text-gray-400",
            secondary:
                "bg-themeSecondary border-themeSecondary text-primary disabled:bg-themeSecondary/60 active:bg-themeSecondary hover:bg-themeSecondaryDark",
        },
    },
    combinedVariants: [
        {
            variant: "outlined",
            color: "primary",
            classes:
                "border-primary text-gray-700 bg-transparent disabled:bg-transparent disabled:border-primary/20 disabled:text-gray-400 hover:bg-gray-50",
        },
        {
            variant: "round",
            size: "xsmall",
            classes: "h-4 w-4",
        },
        {
            variant: "round",
            size: "small",
            classes: "h-6 w-6",
        },
        {
            variant: "round",
            size: "large",
            classes: "h-8 w-8",
        },
        {
            variant: "ghost",
            color: "default",
            classes: "bg-transparent",
        },
    ],
};
