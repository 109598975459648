<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
    >
        <path
            d="M10.03,13.779a5.025,5.025,0,0,1,7.337-1.9.232.232,0,0,0,.266,0,5.024,5.024,0,0,1,3.1-.853A.245.245,0,0,0,21,10.785V4.5A1.5,1.5,0,0,0,19.5,3H9.618a.5.5,0,0,1-.447-.276L8.223.83A1.5,1.5,0,0,0,6.88,0H1.5A1.5,1.5,0,0,0,0,1.5v15A1.5,1.5,0,0,0,1.5,18H9.555a.247.247,0,0,0,.229-.334A5.012,5.012,0,0,1,10.03,13.779Z"
        />
        <path
            d="M22.967,18.519A3.527,3.527,0,0,0,20.581,12.5a3.667,3.667,0,0,0-2.694,1.127l-.211.211a.249.249,0,0,1-.353,0l-.211-.211A3.665,3.665,0,0,0,14.419,12.5a3.528,3.528,0,0,0-2.394,6.012l5.113,5.334a.5.5,0,0,0,.723,0Z"
        />
    </svg>
</template>
