<template>
    <svg
        viewBox="0 0 15 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M13.6829 0.020823C13.6091 0.040458 13.4841 0.0911317 13.4052 0.133344C13.3263 0.175557 11.481 2.04561 9.3045 4.28901L5.34723 8.36788L3.49495 6.4517C1.64643 4.53943 1.64214 4.53534 1.37412 4.45809C1.14956 4.39339 1.06528 4.38934 0.859609 4.43349C0.356693 4.54136 5.15405e-06 4.98092 5.15405e-06 5.49276C5.15405e-06 6.01831 -0.0199128 5.99352 2.41665 8.49387C3.82272 9.93674 4.70784 10.8109 4.82162 10.8691C5.1006 11.0119 5.37502 11.0372 5.67787 10.9482L5.93957 10.8714L10.3402 6.35684C12.7605 3.87387 14.7917 1.7544 14.8539 1.64689C15.1641 1.11114 14.9626 0.405475 14.4168 0.116377C14.2275 0.0161329 13.8736 -0.0299887 13.6829 0.020823Z"
            fill="currentColor"
        />
    </svg>
</template>
