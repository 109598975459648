<template>
    <svg
        viewBox="0 0 26 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M1.92374 0.0966154C0.883244 0.356777 0.223883 1.10429 0.0525564 2.21787C0.0112997 2.48619 -0.00922716 3.41084 0.00398309 4.40892L0.0268809 6.13579L0.218193 6.53004C0.432063 6.97084 0.786572 7.23553 1.3162 7.3498L1.61367 7.41404L1.63467 13.8359C1.65425 19.831 1.66387 20.2826 1.77897 20.6299C2.14147 21.7237 2.86871 22.4774 3.91483 22.8434L4.36255 23H13C21.197 23 21.6582 22.9936 22.044 22.8738C23.1805 22.5208 24.099 21.5046 24.3129 20.3634C24.3556 20.1353 24.3812 17.6472 24.3812 13.7076V7.4161L24.6691 7.36756C25.2835 7.26404 25.7691 6.84456 25.9363 6.27289C25.9925 6.08053 26.01 5.38499 25.9948 3.94207L25.9732 1.88546L25.7623 1.43986C25.4073 0.689811 24.8055 0.226525 23.9824 0.0697424C23.4118 -0.0389838 2.36327 -0.0133448 1.92374 0.0966154ZM23.5682 3.70213V4.9361H13H2.43182V3.70213V2.46816H13H23.5682V3.70213ZM21.9413 13.6938C21.9406 18.5846 21.9216 20.0218 21.8557 20.155C21.8092 20.2493 21.6648 20.3806 21.5349 20.4467C21.313 20.5598 20.7936 20.5659 12.9166 20.5495L4.53449 20.5321L4.31303 20.308L4.09157 20.0839L4.07328 13.7439L4.05499 7.40403H12.9987H21.9424L21.9413 13.6938ZM9.07042 9.93599C8.69125 10.0527 8.45685 10.2286 8.32062 10.4988C7.9617 11.2108 8.32604 12.0684 9.07408 12.2722C9.25306 12.321 10.6082 12.3387 13.1527 12.3254C16.9405 12.3058 16.9648 12.3047 17.2442 12.1535C17.9011 11.798 18.0593 10.8808 17.566 10.2875C17.229 9.88232 17.1056 9.87135 12.9708 9.87786C10.919 9.88108 9.16377 9.90727 9.07042 9.93599Z"
            fill="currentColor"
        />
    </svg>
</template>
