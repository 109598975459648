<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
    >
        <rect
            x="0.5"
            y="2.5"
            width="23"
            height="3"
            rx="1"
            ry="1"
        />
        <rect
            x="0.5"
            y="10.5"
            width="23"
            height="3"
            rx="1"
            ry="1"
        />
        <rect
            x="0.5"
            y="18.5"
            width="23"
            height="3"
            rx="1"
            ry="1"
        />
    </svg>
</template>
