<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
    >
        <path
            d="M23.663,6.763A1,1,0,0,0,22.7,5.5H5.547A.249.249,0,0,1,5.3,5.3l-.572-2.72A3.264,3.264,0,0,0,1.551,0a1.25,1.25,0,0,0,0,2.5.752.752,0,0,1,.733.6L5.45,18.169A3.252,3.252,0,0,0,6.687,20.1a.249.249,0,0,1,.013.389,2,2,0,1,0,3.1.623.248.248,0,0,1,.011-.243.252.252,0,0,1,.213-.118h5.97a.25.25,0,0,1,.224.361A2,2,0,0,0,18.008,24a2,2,0,0,0,1.785-2.9.241.241,0,0,1,.215-.35,1.25,1.25,0,0,0,0-2.5H8.631a.753.753,0,0,1-.734-.595L7.718,16.8a.25.25,0,0,1,.245-.3H18.717a3,3,0,0,0,2.894-2.211Z"
        />
    </svg>
</template>
