<template>
    <svg
        viewBox="0 0 133 131"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M94.7493 50.5365C111.175 30.8418 97.9734 1.03046 72.3862 0.0442928C71.6858 0.0167517 55.1138 -0.00284016 35.5585 0.000337893L0.00386483 0.00457054L0.000154449 36.2583C-0.00249571 56.1972 0.0292965 72.9812 0.0695621 73.5564C1.14402 88.7778 13.4325 100.963 28.69 101.937C43.8882 102.907 57.5812 92.5483 60.706 77.7164C61.2814 74.9893 61.3672 73.6289 61.3821 67.0806L61.3948 61.3722L67.4098 61.3622C73.4946 61.3511 74.5256 61.3034 76.563 60.94C83.6324 59.678 90.3121 55.8567 94.7493 50.5365ZM125.749 79.5365C142.175 59.8418 128.973 30.0305 103.386 29.0443C102.686 29.0168 86.1138 28.9972 66.5585 29.0003L31.0039 29.0046L31.0002 65.2583C30.9975 85.1972 31.0293 101.981 31.0696 102.556C32.144 117.778 44.4325 129.963 59.69 130.937C74.8882 131.907 88.5812 121.548 91.706 106.716C92.2814 103.989 92.3672 102.629 92.3821 96.0806L92.3948 90.3722L98.4098 90.3622C104.495 90.3511 105.526 90.3034 107.563 89.94C114.632 88.678 121.312 84.8567 125.749 79.5365Z"
            fill="currentColor"
        />
    </svg>
</template>
