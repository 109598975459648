<template>
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M19.5 0H4.5C2.01472 0 0 2.01472 0 4.5V19.5C0 21.9853 2.01472 24 4.5 24H9.375C9.99632 24 10.5 23.4963 10.5 22.875V22.875C10.5 22.2537 9.99632 21.75 9.375 21.75H4.5C3.25736 21.75 2.25 20.7426 2.25 19.5V4.5C2.25 3.25736 3.25736 2.25 4.5 2.25H19.5C20.7426 2.25 21.75 3.25736 21.75 4.5V9.625C21.75 10.2463 22.2537 10.75 22.875 10.75V10.75C23.4963 10.75 24 10.2463 24 9.625V4.5C24 2.01472 21.9853 0 19.5 0Z"
            fill="currentColor"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M5.625 6.75C5.625 6.12868 6.12868 5.625 6.75 5.625H15.75C16.3713 5.625 16.875 6.12868 16.875 6.75C16.875 7.37132 16.3713 7.875 15.75 7.875H6.75C6.12868 7.875 5.625 7.37132 5.625 6.75Z"
            fill="white"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M5.625 6.75C5.625 6.12868 6.12868 5.625 6.75 5.625H15.75C16.3713 5.625 16.875 6.12868 16.875 6.75C16.875 7.37132 16.3713 7.875 15.75 7.875H6.75C6.12868 7.875 5.625 7.37132 5.625 6.75Z"
            fill="white"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M5.625 6.75C5.625 6.12868 6.12868 5.625 6.75 5.625H15.75C16.3713 5.625 16.875 6.12868 16.875 6.75C16.875 7.37132 16.3713 7.875 15.75 7.875H6.75C6.12868 7.875 5.625 7.37132 5.625 6.75Z"
            fill="currentColor"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M5.625 12C5.625 11.3787 6.12868 10.875 6.75 10.875H11.25C11.8713 10.875 12.375 11.3787 12.375 12C12.375 12.6213 11.8713 13.125 11.25 13.125H6.75C6.12868 13.125 5.625 12.6213 5.625 12Z"
            fill="white"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M5.625 12C5.625 11.3787 6.12868 10.875 6.75 10.875H11.25C11.8713 10.875 12.375 11.3787 12.375 12C12.375 12.6213 11.8713 13.125 11.25 13.125H6.75C6.12868 13.125 5.625 12.6213 5.625 12Z"
            fill="white"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M5.625 12C5.625 11.3787 6.12868 10.875 6.75 10.875H11.25C11.8713 10.875 12.375 11.3787 12.375 12C12.375 12.6213 11.8713 13.125 11.25 13.125H6.75C6.12868 13.125 5.625 12.6213 5.625 12Z"
            fill="currentColor"
        />
        <path
            d="M13.7959 19.9244L17.7664 23.6312C17.9312 23.785 18.1487 23.8707 18.375 23.8707C18.6013 23.8707 18.8188 23.785 18.9836 23.6312L22.9541 19.9244C23.6221 19.3026 24 18.4303 24 17.5184V17.391C24 15.8551 22.8904 14.5455 21.3765 14.2928C20.3745 14.1258 19.355 14.4532 18.6387 15.1695L18.375 15.4332L18.1113 15.1695C17.395 14.4532 16.3755 14.1258 15.3735 14.2928C13.8596 14.5455 12.75 15.8551 12.75 17.391V17.5184C12.75 18.4303 13.1279 19.3026 13.7959 19.9244Z"
            fill="currentColor"
        />
    </svg>
</template>
