<template>
    <svg
        viewBox="0 0 22 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9.91279 0.0787427C8.36658 0.403666 6.83143 1.59323 6.11905 3.0182C5.08199 5.09286 5.51597 7.84534 7.13782 9.47917C9.81579 12.1771 14.3145 11.3967 15.8973 7.95968C17.557 4.35601 15.3168 0.406946 11.3946 0.0218814C10.9405 -0.0226392 10.2736 0.00297943 9.91279 0.0787427ZM2.2958 18.8871C0.861193 19.4176 0 20.7821 0 22.5247C0 23.7958 0.542819 24.7638 1.64383 25.4563C2.38604 25.9229 2.42678 25.9296 4.82972 25.9788L7.26372 26.0287V37.2462V48.4638L4.91935 48.5322C2.92255 48.5905 2.48381 48.6491 1.96005 48.9275C0.659807 49.6185 -0.00122994 50.7683 0.00445805 52.329C0.010146 53.9013 0.620452 54.9409 1.92592 55.6018L2.71225 56L10.8681 55.9991C16.3573 55.9986 19.2251 55.9417 19.6389 55.8253C20.4998 55.5831 21.6277 54.4074 21.8677 53.502C21.9698 53.1165 22.0245 52.3957 21.9893 51.9003C21.8876 50.471 20.8456 49.2172 19.4265 48.8167C19.1206 48.7303 17.9192 48.6594 16.7565 48.6589L14.6427 48.658L14.6394 35.1065C14.636 21.8039 14.6303 21.5421 14.3224 20.8521C13.9322 19.9776 13.5113 19.5116 12.7211 19.0795C12.1356 18.7593 11.8858 18.7415 7.49432 18.7067C3.87168 18.6779 2.75652 18.7167 2.2958 18.8871Z"
            fill="currentColor"
        />
    </svg>
</template>
