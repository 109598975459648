<template>
    <svg
        viewBox="0 0 13 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M12.25 6.73486L6.89 1.37536C6.85522 1.34053 6.81391 1.31289 6.76844 1.29404C6.72297 1.27519 6.67422 1.26548 6.625 1.26548C6.57578 1.26548 6.52703 1.27519 6.48156 1.29404C6.43609 1.31289 6.39478 1.34053 6.36 1.37536L1 6.73486"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>
