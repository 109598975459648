<template>
    <svg
        viewBox="0 0 11 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M2.9551 0.0444228C2.62735 0.165285 2.5408 0.389105 2.5408 1.11597V1.61448L1.9097 1.63096C1.23378 1.6486 1.07091 1.68298 0.754437 1.87482C0.455934 2.05578 0.174545 2.44156 0.0603563 2.8264C0.00308173 3.01946 0 3.24941 0 7.31974C0 11.3695 0.00334406 11.6208 0.0595695 11.8091C0.222082 12.3536 0.579925 12.7385 1.09471 12.9225L1.31138 13H5.5078C9.68526 13 9.70513 12.9997 9.90433 12.9288C10.4404 12.7381 10.8681 12.2424 10.9663 11.6983C11.0113 11.4484 11.0112 3.18956 10.9662 2.94373C10.8641 2.3869 10.4213 1.88039 9.88453 1.70635C9.75047 1.66291 9.54229 1.64361 9.07311 1.6312L8.44201 1.61448L8.44181 1.08437C8.44171 0.792827 8.4264 0.497521 8.40778 0.428171C8.35467 0.230396 8.23068 0.103837 8.03125 0.0438803C7.81363 -0.0215699 7.6421 0.0169881 7.4748 0.168914C7.30517 0.322976 7.26177 0.516715 7.26177 1.12001V1.62252H5.4914H3.72104L3.72084 1.0884C3.72075 0.794625 3.70544 0.497521 3.68681 0.428171C3.63482 0.234534 3.50994 0.104752 3.3192 0.0461523C3.11981 -0.0150928 3.11653 -0.0150927 2.9551 0.0444228ZM9.81883 8.19297C9.81873 11.4188 9.81699 11.5108 9.75483 11.6163C9.71247 11.6881 9.64904 11.7372 9.56645 11.7619C9.47803 11.7884 8.2752 11.7963 5.41826 11.7892L1.39459 11.7792L1.28741 11.6683L1.18024 11.5575L1.17142 8.21776L1.1626 4.87807H5.49078H9.81896L9.81883 8.19297Z"
            fill="currentColor"
        />
    </svg>
</template>
