<template>
    <svg
        viewBox="0 0 10 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M2.04741 0.0805853C1.26177 0.248506 0.634396 0.746962 0.252722 1.50638L0.0248198 1.95985L0.00612266 3.93492C-0.014672 6.12526 -0.00208726 6.21772 0.367242 6.58941C0.774684 6.99936 0.922284 7.03704 2.12226 7.03704C3.13766 7.03704 3.21634 7.02896 3.46318 6.89936C3.78673 6.72956 3.99252 6.51942 4.13664 6.21158C4.23054 6.01094 4.24702 5.82167 4.24702 4.94392C4.24702 4.06617 4.23054 3.87691 4.13664 3.67626C3.99228 3.3679 3.78625 3.15782 3.46318 2.98948C3.22875 2.86726 3.10356 2.85057 2.28268 2.8317L1.36448 2.8106L1.40475 2.56538C1.45941 2.2326 1.6263 1.87577 1.80123 1.71781C1.99366 1.544 2.40517 1.40626 2.73201 1.40626C3.2384 1.40626 3.53054 1.14737 3.53054 0.698732C3.53054 0.0741585 3.03806 -0.131144 2.04741 0.0805853ZM7.80039 0.0805853C7.01475 0.248506 6.38737 0.746962 6.0057 1.50638L5.7778 1.95985L5.7591 3.93492C5.7383 6.12526 5.75089 6.21772 6.12022 6.58941C6.52766 6.99936 6.67526 7.03704 7.87523 7.03704C8.89063 7.03704 8.96932 7.02896 9.21616 6.89936C9.5397 6.72956 9.74549 6.51942 9.88962 6.21158C9.98352 6.01094 10 5.82167 10 4.94392C10 4.06617 9.98352 3.87691 9.88962 3.67626C9.74525 3.3679 9.53922 3.15782 9.21616 2.98948C8.98172 2.86726 8.85654 2.85057 8.03566 2.8317L7.11746 2.8106L7.15773 2.56538C7.21238 2.2326 7.37928 1.87577 7.55421 1.71781C7.74663 1.544 8.15815 1.40626 8.48499 1.40626C8.99137 1.40626 9.28351 1.14737 9.28351 0.698732C9.28351 0.0741585 8.79104 -0.131144 7.80039 0.0805853Z"
            fill="currentColor"
        />
    </svg>
</template>
