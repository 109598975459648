<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
    >
        <line
            class="a"
            x1="8.25"
            y1="18.75"
            x2="2.447"
            y2="18.75"
        />
        <path
            class="a"
            d="M20.25,8.25v-3a1.5,1.5,0,0,0-1.5-1.5H8.25V2.25A1.5,1.5,0,0,0,6.75.75H2.25a1.5,1.5,0,0,0-1.5,1.5v14.8a1.7,1.7,0,0,0,3.336.438L6.437,9.334A1.5,1.5,0,0,1,7.879,8.25H21.75A1.5,1.5,0,0,1,23.2,10.136"
        />
        <path
            class="a"
            d="M17.25,23.25l-5.114-5.335a3.025,3.025,0,0,1-.567-3.492h0a3.026,3.026,0,0,1,4.846-.786l.835.835.835-.835a3.026,3.026,0,0,1,4.846.786h0a3.025,3.025,0,0,1-.567,3.492Z"
        />
    </svg>
</template>
