<template>
    <svg
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.72231 0.0156518C7.47184 0.0683772 7.35386 0.15914 6.72587 0.782245L6.11767 1.38568L7.36688 2.63197L8.61606 3.87826L9.23521 3.25679C9.97002 2.51924 10 2.47348 10 2.09008C10 1.71437 9.96589 1.66253 9.23074 0.920255C8.89196 0.578153 8.55853 0.256973 8.48979 0.206517C8.26238 0.0394969 7.96288 -0.0349866 7.72231 0.0156518ZM3.10625 4.39286C1.68681 5.81018 0.525426 6.97753 0.525426 6.98695C0.525426 6.99639 0.402557 7.60254 0.252402 8.33396C0.0163082 9.48388 -0.0169901 9.67696 0.00621938 9.76086C0.0364857 9.87031 0.136407 9.97516 0.233688 9.99952C0.306818 10.0178 2.89391 9.50232 3.02149 9.44402C3.06461 9.42429 4.24662 8.2635 5.64818 6.86441L8.19649 4.32064L6.94179 3.06825L5.6871 1.81586L3.10625 4.39286Z"
            fill="currentColor"
        />
    </svg>
</template>
