<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
    >
        <path
            d="M21,17.5A1.5,1.5,0,0,1,19.5,16V11.138A7.957,7.957,0,0,0,13,3.073V1a1,1,0,0,0-2,0V3.073a7.957,7.957,0,0,0-6.5,8.065V16A1.5,1.5,0,0,1,3,17.5a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2Z"
        ></path>
        <path
            d="M14.236,21H9.764a.25.25,0,0,0-.248.222A2.319,2.319,0,0,0,9.5,21.5a2.5,2.5,0,1,0,5,0,2.319,2.319,0,0,0-.016-.278A.248.248,0,0,0,14.236,21Z"
        ></path>
    </svg>
</template>
