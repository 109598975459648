<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
    >
        <path
            d="M16.5,20.053h0c-1.423-.141-2.3-.649-2.47-2.694a.5.5,0,0,0-.565-.455,11.2,11.2,0,0,1-2.927,0,.5.5,0,0,0-.565.455C9.8,19.407,8.922,19.912,7.5,20.053A1.979,1.979,0,0,0,7.7,24H16.3a1.979,1.979,0,0,0,.194-3.947Z"
            fill="currentColor"
        />
        <path
            d="M22,5.5h-.246a.5.5,0,0,1-.5-.565A29.845,29.845,0,0,0,21.5,1.522,1.5,1.5,0,0,0,20,0H4A1.5,1.5,0,0,0,2.5,1.523a29.827,29.827,0,0,0,.238,3.412.5.5,0,0,1-.5.565H2a2,2,0,0,0-2,2V10a5.019,5.019,0,0,0,3.266,4.691,1,1,0,1,0,.693-1.877A3.01,3.01,0,0,1,2,10V8a.5.5,0,0,1,.5-.5h.343a.5.5,0,0,1,.482.366C4.688,12.786,7.7,15.5,12,15.5s7.312-2.714,8.675-7.634a.5.5,0,0,1,.482-.366H21.5A.5.5,0,0,1,22,8v2a3.01,3.01,0,0,1-1.959,2.814,1,1,0,1,0,.693,1.877A5.019,5.019,0,0,0,24,10V7.5A2,2,0,0,0,22,5.5Zm-5.216-.879L12.731,9.74a2,2,0,0,1-2.982.173L8.043,8.207A1,1,0,1,1,9.457,6.793L10.766,8.1a.5.5,0,0,0,.382.146.507.507,0,0,0,.364-.189l3.7-4.68a1,1,0,1,1,1.568,1.242Z"
            fill="currentColor"
        />
    </svg>
</template>
