<template>
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M13.2025 23.9496C11.8189 23.6711 10.9196 22.3159 11.1963 20.9231L11.3187 20.3125C11.6008 18.8822 12.1223 17.5216 12.8513 16.2843H5.18834C3.77814 16.2843 2.63402 15.1326 2.63402 13.7131C2.63402 12.7221 3.19277 11.8597 4.01229 11.4311C3.43224 10.9597 3.05974 10.2366 3.05974 9.42773C3.05974 8.17427 3.95375 7.12972 5.1298 6.90474C4.89566 6.5137 4.76262 6.05839 4.76262 5.57093C4.76262 4.42996 5.50231 3.46041 6.52404 3.12829C6.48679 2.95152 6.4655 2.76404 6.4655 2.5712C6.4655 1.15168 7.60963 0 9.01983 0H14.2083C15.2194 0 16.2039 0.299973 17.0447 0.862423L19.0934 2.23909C20.5143 3.19257 21.3657 4.79957 21.3657 6.51906V8.57066V11.1419V12.4757C21.3657 14.0398 20.658 15.5129 19.45 16.4932L19.0562 16.8092C17.646 17.9448 16.6828 19.5411 16.3316 21.3195L16.2092 21.9302C15.9325 23.3229 14.5861 24.2282 13.2025 23.9496Z"
            fill="currentColor"
        />
    </svg>
</template>
