<template>
    <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M3.25 15.25C5.04493 15.25 6.5 13.7949 6.5 12C6.5 10.2051 5.04493 8.75 3.25 8.75C1.45507 8.75 0 10.2051 0 12C0 13.7949 1.45507 15.25 3.25 15.25Z"
            fill="currentColor"
        />
        <path
            d="M12 15.25C13.7949 15.25 15.25 13.7949 15.25 12C15.25 10.2051 13.7949 8.75 12 8.75C10.2051 8.75 8.75 10.2051 8.75 12C8.75 13.7949 10.2051 15.25 12 15.25Z"
            fill="currentColor"
        />
        <path
            d="M20.75 15.25C22.5449 15.25 24 13.7949 24 12C24 10.2051 22.5449 8.75 20.75 8.75C18.9551 8.75 17.5 10.2051 17.5 12C17.5 13.7949 18.9551 15.25 20.75 15.25Z"
            fill="currentColor"
        />
    </svg>
</template>
