import { normalizeClass, PropType, ref, Ref } from "vue";
import { twMerge } from "tailwind-merge";
import { ResponsivePrefixes } from "@/types/responsive";

type FilterNotStartingWith<T, N extends string> = T extends `${N}:${string}`
    ? never
    : T;
type KeysNonOptional<T> = {
    [K in keyof T]-?: T[K];
};
type IsStringLiteral<T> = T extends string
    ? string extends T
        ? never
        : T
    : never;
type Unarray<T> = T extends Array<infer U> ? U : T;

export type AntlerClassValues<T extends object> = {
    [K in keyof T as Unarray<KeysNonOptional<T>[K]> extends IsStringLiteral<
        Unarray<T[K]>
    >
        ? K
        : never]?: {
        [NK in FilterNotStartingWith<
            Unarray<T[K]>,
            ResponsivePrefixes
        > as NK extends string ? NK : never]: string;
    };
};

type AntlerClassKey<T extends object> = {
    [Variant in keyof T | "classes"]?: string | null | undefined;
};

export type AntlerClasses<T extends object> = {
    base?: string;
    variants?: AntlerClassValues<T>;
    combinedVariants?: AntlerClassKey<T>[];
};

type AClass = (modifiers?: object) => string;

type AComponentType = {
    classPrefix: Ref<string>;
    aClass: AClass;
};

type AttrType = {
    class?: string;
};

export function installAntlerComponent(
    baseId: string,
    props: object = {},
    classes: AntlerClasses<object> = {},
    attrs: AttrType = {},
): AComponentType {
    /**
     * Generate the global component prefix
     */

    const classPrefix = ref(baseId);

    /**
     * Generate Tailwind classes based on defaults and local config
     *
     * @param modifiers
     */

    const aClass = (modifiers: object = {}) => {
        /**
         * Set defaults
         */

        // set component defaults
        const baseClasses = classes.base ? [classes.base] : [];

        /**
         * Set props
         */

        const { variants } = classes;

        // set local project overrides
        let variantClasses: (string | undefined)[] = [];
        if (variants && Object.keys(variants).length) {
            variantClasses = Object.keys(variants)
                .map(variant => {
                    // takes eg: small md:regular lg:large
                    // and outputs: ['small', 'md:regular', 'lg:large']

                    const propValues = props[
                        variant as keyof typeof props
                    ] as string;

                    if (!propValues) {
                        return;
                    }

                    const propValueArray = Array.isArray(propValues)
                        ? propValues
                        : propValues.split(" ");

                    const propValueClasses = propValueArray
                        ?.map((value: string) => {
                            // generates ['small'], ['md', 'regular'], ['lg', 'large']
                            const prefixedPropKey: string[] = value.split(":");
                            if (prefixedPropKey.length > 1) {
                                const prefixedPropKeyValue: string =
                                    variants[
                                        variant as keyof typeof variants
                                    ]?.[prefixedPropKey[1]];

                                if (prefixedPropKeyValue) {
                                    return prefixedPropKeyValue
                                        .split(" ")
                                        .map(
                                            (c: string) =>
                                                `${prefixedPropKey[0]}:${c}`,
                                        );
                                }
                            } else {
                                const prefixedPropKeyValue: string =
                                    variants[
                                        variant as keyof typeof variants
                                    ]?.[prefixedPropKey[0]];

                                if (prefixedPropKeyValue) {
                                    return prefixedPropKeyValue;
                                }
                            }
                        })
                        .filter(x => x)
                        .flat();

                    if (propValueClasses) {
                        return propValueClasses;
                    }
                })
                .filter(x => x)
                .flat();
        }

        /** Combined Variants */
        const propsWithoutUndefined =
            props &&
            Object.entries(props).reduce((acc, [key, value]) => {
                if (value === undefined) {
                    return acc;
                }
                acc[key] = value;
                return acc;
            }, {} as Record<string, unknown>);

        const combinedVariantClasses = classes?.combinedVariants?.reduce(
            (acc: string[], { classes, ...compoundVariantOptions }) => {
                const hasBothVariants = Object.entries(
                    compoundVariantOptions,
                ).every(([key, value]) => {
                    return Array.isArray(value)
                        ? value.includes(propsWithoutUndefined[key])
                        : propsWithoutUndefined[key] === value;
                });
                if (classes && hasBothVariants) {
                    acc.push(classes);
                }
                return acc;
            },
            [],
        );

        /**
         * Class overrides in templates
         */
        if (attrs && attrs.class) {
            baseClasses.push(attrs.class);
        }

        /**
         * Set modifiers
         * such as:
         *         aClass(
         *             'inline-flex align-middle px-4 py-1 bg-gray-100',
         *             {
         *                 [buttonElements.base.isDisabled]: {'opacity-50': props.disabled}, <--
         *             }
         *         )
         */
        const modifierClasses = Object.keys(modifiers).map(
            (modifierClasses: string) => {
                const classExists =
                    modifiers[modifierClasses as keyof typeof modifiers];
                if (classExists) {
                    return modifierClasses.replaceAll("_", " ");
                }
            },
        );

        return cn(
            baseClasses,
            variantClasses,
            combinedVariantClasses,
            modifierClasses,
        );
    };

    return {
        classPrefix,
        aClass,
    };
}

function cn(...inputs: Parameters<typeof normalizeClass>) {
    return twMerge(normalizeClass(inputs));
}

export const defaultComponentProps = {
    extendedProps: {
        type: Object as PropType<object>,
        default: () => ({}),
    },
};

// responsive helper to be used in extended components
export function responsive(string: string) {
    return string;
}
