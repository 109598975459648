<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
    >
        <path
            d="M19.618,10.679c-3.011-2.67-7.125-2.853-8.859-2.793a.249.249,0,0,1-.259-.25V5.064A1,1,0,0,0,8.858,4.3L1.364,10.666A1,1,0,0,0,1,11.438a.986.986,0,0,0,.351.76l7.513,6.637a1,1,0,0,0,1.636-.773V15.027a.249.249,0,0,1,.2-.244c1.908-.4,8.135-1.158,11.36,4.89A.5.5,0,0,0,23,19.438C23,15.617,21.862,12.671,19.618,10.679Z"
        />
    </svg>
</template>
