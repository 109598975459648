<template>
    <svg
        viewBox="0 0 28 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M11.5369 0.043829C11.3987 0.0814424 11.1536 0.181483 10.9923 0.2662C10.8311 0.350917 8.37257 2.83566 5.52894 5.78778C-0.117524 11.65 0.000188581 11.507 0.000188581 12.505C0.000188581 13.503 -0.11744 13.36 5.52894 19.2225C9.15333 22.9855 10.8118 24.6496 11.0761 24.7883C11.6117 25.0694 12.4006 25.0707 12.9478 24.7913C13.9299 24.2899 14.301 22.8958 13.7245 21.8735C13.6082 21.6672 11.9993 19.9451 10.1491 18.0466L6.78499 14.5946H16.5394C27.4687 14.5946 26.8875 14.627 27.4442 13.9866C27.8441 13.5266 28 13.111 28 12.505C28 11.899 27.8441 11.4833 27.4442 11.0234C26.8875 10.383 27.4687 10.4154 16.5394 10.4154H6.78499L10.1491 6.96337C11.9993 5.06486 13.6082 3.34274 13.7245 3.13648C14.2922 2.1298 13.9281 0.714861 12.9784 0.237032C12.5595 0.0263282 11.9177 -0.0596947 11.5369 0.043829Z"
            fill="currentColor"
        />
    </svg>
</template>
