<template>
    <svg
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0)">
            <path
                d="M8.97656 12.1021C12.0476 10.7969 13.4792 7.24927 12.174 4.17821C10.8688 1.10714 7.32118 -0.324402 4.25011 0.98077C1.17905 2.28594 -0.252495 5.83359 1.05268 8.90465C2.35785 11.9757 5.90549 13.4073 8.97656 12.1021Z"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
                fill="transparent"
            />
            <path
                d="M10.8853 10.8133L15.5713 15.5"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </g>
        <defs>
            <clipPath id="clip0">
                <rect
                    width="16"
                    height="16"
                    fill="#fff"
                    transform="translate(0.0712891)"
                />
            </clipPath>
        </defs>
    </svg>
</template>
