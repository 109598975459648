<template>
    <svg
        viewBox="0 0 13 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M12.25 1.26532L6.89 6.62482C6.85522 6.65965 6.81391 6.68729 6.76844 6.70614C6.72297 6.72499 6.67422 6.7347 6.625 6.7347C6.57578 6.7347 6.52703 6.72499 6.48156 6.70614C6.43609 6.68729 6.39478 6.65965 6.36 6.62482L1 1.26532"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>
