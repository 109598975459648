<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
    >
        <path
            d="M19.5,8.99H4.5a.5.5,0,0,0-.5.5v12.5a2,2,0,0,0,2,2H18a2,2,0,0,0,2-2V9.49A.5.5,0,0,0,19.5,8.99Zm-9.25,11.5a.75.75,0,0,1-1.5,0V11.865a.75.75,0,0,1,1.5,0Zm5,0a.75.75,0,0,1-1.5,0V11.865a.75.75,0,0,1,1.5,0Z"
        ></path>
        <path
            d="M20.922,4.851A11.8,11.8,0,0,0,16.8,3.78a4.945,4.945,0,0,0-9.607,0A12.165,12.165,0,0,0,3.181,4.805,1.943,1.943,0,0,0,2,6.476,1,1,0,0,0,3,7.49H21a1,1,0,0,0,1-.985A1.874,1.874,0,0,0,20.922,4.851ZM11.977,2.01A2.884,2.884,0,0,1,14.6,3.579a44.676,44.676,0,0,0-5.2,0A2.834,2.834,0,0,1,11.977,2.01Z"
        ></path>
    </svg>
</template>
